<template>
  <div class="w-full bg-black">
    <!-- START LOADER -->
    <div
      v-if="loading"
      class="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-screen"
    >
      <img
        ref="vid"
        src="@/assets/images/pantherBg.png"
        class="w-px h-px opacity-0 absolute z-0"
        alt=""
      />
      <div
        class="bg-[#1B1B1B] absolute load1 top-0 left-0 w-full h-screen z-0"
      ></div>
      <div
        class="bg-[#1B1B1B] absolute load2 top-0 left-0 w-full h-screen z-0"
      ></div>
      <div class="img1">
        <img
          src="@/assets/images/miniLogo.png"
          class="z-[1] zoom img1"
          alt="Logo"
        />
      </div>
    </div>
    <div class="w-full bg-realBlack">
      <div class="relative w-full bg-realBlack">
        <section
          class="w-full relative md:min-h-0 min-h-[87vh] md:max-h-none max-h-[87vh] pantBg"
        >
          <!-- ref="vid" -->
          <img
            src="@/assets/images/pantherBg.png"
            class="hidden w-full md:block"
            alt=""
          />
          <div
            class="top-0 left-0 right-0 bottom-0 absolute flex flex-col items-center justify-between pb-[5vh] xl:pt-[22vh] md:pt-[8vh] pt-[20vh] h-full md:min-h-0 min-h-[87vh] md:max-h-none max-h-[87vh] w-[90%] mx-auto"
          >
            <div class="w-full">
              <h1
                class="capitalize font-belgiano text-realBlack md:text-[80px] text-4xl text-center font-normal md:leading-[70px]"
              >
                Panther<span class="text-green">.</span>
              </h1>
              <div
                class="flex flex-col items-stretch justify-center w-full gap-2 mt-3 md:flex-row md:gap-6"
              >
                <p
                  class="font-inter tracking-[1.2px] text-center md:text-sm text-xs font-light text-realBlack md:w-auto w-full"
                >
                  Starting from ₦{{ prcs.panther }}
                  million
                </p>
                <div class="flex items-stretch justify-center gap-2 md:gap-6">
                  <div class="hidden w-px h-4 bg-realBlack md:block"></div>
                  <p
                    class="font-inter tracking-[1.2px] md:text-sm text-xs text-center font-light text-realBlack"
                  >
                    10% down payment
                  </p>
                  <div class="w-px h-4 bg-realBlack"></div>
                  <p
                    class="font-inter tracking-[1.2px] md:text-sm text-xs text-center font-light text-realBlack"
                  >
                    Spread over 5 years
                  </p>
                </div>
              </div>
            </div>
            <div class="w-full">
              <!-- <div
              class="flex items-center justify-center max-w-xl mx-auto w-[90%] gap-12 mt-12"
            </div> -->
              <div
                class="flex items-center justify-center w-full gap-3 md:gap-6"
              >
                <div
                  class="flex flex-col items-center justify-between w-1/2 md:w-auto md:block"
                >
                  <div
                    class="flex flex-col items-end mb-6 text-left text-black md:pr-8"
                  >
                    <div>
                      <p class="font-inter text-xs uppercase tracking-[1.25px]">
                        Speed
                      </p>
                      <p class="mt-1 font-semibold">190Km/H</p>
                    </div>
                  </div>
                  <router-link class="block w-full" :to="{ name: 'panther' }">
                    <button class="w-full black-btn long">Explore</button>
                  </router-link>
                </div>
                <div
                  class="flex flex-col items-center justify-between w-1/2 md:w-auto md:block"
                >
                  <div class="mb-6 text-left text-black md:pl-8">
                    <p class="font-inter text-xs uppercase tracking-[1.25px]">
                      Range
                    </p>
                    <p class="mt-1 font-semibold">580 km</p>
                  </div>
                  <router-link
                    :to="{ name: 'pantherOrderNow' }"
                    class="block w-full"
                  >
                    <button class="w-full white-btn long">Order Now</button>
                  </router-link>
                </div>
              </div>
              <!-- <div class="w-full"></div> -->
            </div>
          </div>
        </section>
      </div>
      <!-- END JAVAN -->
      <div
        class="relative md:max-h-none max-h-[87vh] overflow-hidden w-full bg-realBlack"
      >
        <img
          src="@/assets/images/savannahBg.jpg"
          class="hidden w-full md:block"
          alt=""
        />
        <section
          class="w-full md:min-h-0 md:absolute md:top-0 md:left-0 md:right-0 md:bottom-0 md:max-h-none md:overflow-hidden savBg pb-[5vh] md:h-auto h-[87vh] xl:pt-[10vh] md:pt-[6vh] pt-[10vh]"
        >
          <div
            class="flex flex-col items-center justify-between h-full w-[90%] mx-auto"
          >
            <div class="w-full">
              <h1
                class="capitalize font-belgiano text-realBlack md:text-[80px] text-4xl text-center font-normal md:leading-[70px]"
              >
                Savannah<span class="text-green">.</span>
              </h1>
              <div
                class="flex flex-col items-stretch justify-center w-full gap-2 mt-3 md:flex-row md:gap-6"
              >
                <p
                  class="font-inter tracking-[1.2px] text-center md:text-sm text-xs font-light text-realBlack"
                >
                  Starting from ₦{{ prcs.savannah }} million
                </p>
                <div class="flex items-stretch justify-center gap-2 md:gap-6">
                  <div class="hidden w-px h-4 bg-realBlack md:block"></div>
                  <p
                    class="font-inter tracking-[1.2px] md:text-sm text-xs text-center font-light text-realBlack"
                  >
                    10% down payment
                  </p>
                  <div class="w-px h-4 bg-realBlack"></div>
                  <p
                    class="font-inter tracking-[1.2px] md:text-sm text-xs text-center font-light text-realBlack"
                  >
                    Spread over 5 years
                  </p>
                </div>
              </div>
            </div>
            <div class="w-full">
              <!-- <div
              class="flex items-center justify-center max-w-xl mx-auto w-[90%] gap-12 mt-12"
            </div> -->
              <div
                class="flex items-center justify-center w-full gap-3 md:gap-6"
              >
                <div
                  class="flex flex-col items-center justify-between w-1/2 md:w-auto md:block"
                >
                  <div
                    class="flex flex-col items-end mb-6 text-left text-black md:pr-8"
                  >
                    <div>
                      <p class="font-inter text-xs uppercase tracking-[1.25px]">
                        Speed
                      </p>
                      <p class="mt-1 font-semibold">180Km/H</p>
                    </div>
                  </div>
                  <router-link
                    class="flex items-center justify-center w-full"
                    :to="{ name: 'savannah' }"
                  >
                    <button class="w-full black-btn long">Explore</button>
                  </router-link>
                </div>
                <div
                  class="flex flex-col items-center justify-between w-1/2 md:w-auto md:block"
                >
                  <div class="mb-6 text-left text-black md:pl-8">
                    <p class="font-inter text-xs uppercase tracking-[1.25px]">
                      Range
                    </p>
                    <p class="mt-1 font-semibold">460 km</p>
                  </div>
                  <router-link
                    class="flex items-center justify-center w-full"
                    :to="{ name: 'order-savannah' }"
                  >
                    <button class="w-full white-btn long">Order Now</button>
                  </router-link>
                </div>
              </div>
              <!-- TEMPORARY BUTTON -->
              <!-- <div
                class="flex items-center justify-center w-full gap-3 md:gap-6"
              >
                <router-link
                  class="flex items-center justify-center w-full"
                  :to="{ name: 'order-savannah' }"
                >
                  <button class="w-full black-btn long">Order Now</button>
                </router-link>
              </div> -->
              <!-- TEMPORARY BUTTON -->
            </div>
          </div>
        </section>
      </div>
      <!-- <div
      class="fixed z-[1] pin top-0 left-0 right-0 max-h-screen overflow-hidden w-full bg-realBlack"
    >
      <section
        class="w-full md:min-h-screen md:max-h-screen md:overflow-hidden caspBg pb-[5vh] h-screen"
      >
        <div
          class="flex flex-col items-center justify-center w-full h-full md:justify-between"
        >
          <div class="w-full">
            <h1
              class="capitalize font-belgiano text-realBlack md:text-[80px] text-4xl text-center font-normal md:leading-[1600x]"
            >
              Caspian<span class="text-green">.</span>
            </h1>
            <div class="flex items-stretch justify-center gap-6">
              <p
                class="uppercase font-inter tracking-[1.2px] text-center font-light text-realBlack"
              >
                Starting from ₦{{ prcs.panther }} million
              </p>
              <div class="w-px h-4 bg-realBlack"></div>
              <p
                class="uppercase font-inter tracking-[1.2px] md:text-sm text-xs text-center font-light text-realBlack"
              >
                10% down payment
              </p>
              <div class="w-px h-4 bg-realBlack"></div>
              <p
                class="uppercase font-inter tracking-[1.2px] md:text-sm text-xs text-center font-light text-realBlack"
              >
                Spread over 5 years
              </p>
            </div>
          </div>
          <div class="w-full">
            
            <div class="flex items-center justify-center w-full gap-6">
              <div class="">
                <div
                  class="flex flex-col items-end pr-8 mb-6 text-left text-black"
                >
                  <div>
                    <p class="font-inter text-xs uppercase tracking-[1.25px]">
                      Speed
                    </p>
                    <p class="mt-1 font-semibold">101Km/H</p>
                  </div>
                </div>
                <button class="w-full black-btn long">Explore</button>
              </div>
              <div>
                <div class="pl-8 mb-6 text-left text-black">
                  <p class="font-inter text-xs uppercase tracking-[1.25px]">
                    Range
                  </p>
                  <p class="mt-1 font-semibold">401 km</p>
                </div>
                <button class="w-full white-btn long">Order Now</button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div> -->
      <!-- SEAL -->
      <section
        class="md:min-h-screen min-h-[87vh] bacc3 w-full md:max-h-screen max-h-[87vh]"
      >
        <div
          class="flex flex-col items-center justify-between w-[90%] mx-auto pb-[5vh] pt-[10vh] md:min-h-screen md:max-h-screen md:h-screen h-[87vh]"
        >
          <div class="w-full">
            <h1
              class="capitalize font-belgiano text-realBlack md:text-[80px] text-4xl text-center font-normal md:leading-[70px]"
            >
              Sill<span class="text-green">.</span>
            </h1>
            <div
              class="flex flex-col items-stretch justify-center gap-2 mt-3 md:flex-row md:gap-6"
            >
              <p
                class="font-inter tracking-[1.2px] text-center font-light text-realBlack md:text-sm text-xs"
              >
                Starting from ₦{{ prcs.sill }} million
              </p>
              <div class="flex items-stretch justify-center gap-2 md:gap-6">
                <div class="hidden w-px h-4 bg-realBlack md:block"></div>
                <p
                  class="font-inter tracking-[1.2px] md:text-sm text-xs text-center font-light text-realBlack"
                >
                  10% down payment
                </p>
                <div class="w-px h-4 bg-realBlack"></div>
                <p
                  class="font-inter tracking-[1.2px] md:text-sm text-xs text-center font-light text-realBlack"
                >
                  Spread over 5 years
                </p>
              </div>
            </div>
          </div>
          <div class="w-full">
            <!-- <div
            class="flex items-center justify-center max-w-xl mx-auto w-[90%] gap-12 mt-12"
          ></div> -->
            <div class="flex items-center justify-center w-full gap-3 md:gap-6">
              <div
                class="flex flex-col items-center justify-between w-1/2 md:w-auto md:block"
              >
                <div
                  class="flex flex-col items-end mb-6 text-left text-black md:pr-8"
                >
                  <div>
                    <p class="font-inter text-xs uppercase tracking-[1.25px]">
                      Speed
                    </p>
                    <p class="mt-1 font-semibold">185Km/H</p>
                  </div>
                </div>
                <router-link
                  class="flex items-center justify-center w-full"
                  :to="{ name: 'sill' }"
                >
                  <button class="w-full black-btn long">Explore</button>
                </router-link>
              </div>
              <div
                class="flex flex-col items-center justify-between w-1/2 md:w-auto md:block"
              >
                <div class="mb-6 text-left text-black md:pl-8">
                  <p class="font-inter text-xs uppercase tracking-[1.25px]">
                    Range
                  </p>
                  <p class="mt-1 font-semibold">520 km</p>
                </div>
                <router-link
                  class="flex items-center justify-center w-full"
                  :to="{ name: 'order-sill' }"
                >
                  <button class="w-full white-btn long">Order Now</button>
                </router-link>
              </div>
            </div>
            <!-- TEMPORARY BUTTON -->
            <!-- <div class="flex items-center justify-center w-full gap-3 md:gap-6">
              <router-link
                class="flex items-center justify-center w-full"
                :to="{ name: 'order-sill' }"
              >
                <button class="w-full black-btn long">Order Now</button>
              </router-link>
            </div> -->
            <!-- TEMPORARY BUTTON -->
          </div>
        </div>
      </section>
      <!-- END SEAL -->

      <!-- VEHICLES -->
      <section class="">
        <div class="outer">
          <div class="inner">
            <div class="bg">
              <div
                class="w-full ccc text-black md:py-[14vh] py-12 h-full bg-white vehs"
              >
                <!-- <div class="w-[95%] max-w-[1650px] mx-auto relative"> -->
                <div class="w-[90%] max-w-xl mx-auto relative">
                  <div class="w-full mb-10">
                    <h3
                      class="w-full text-3xl font-medium text-left font-belgiano text-realBlack md:text-5xl"
                    >
                      Experience the Future of <br class="hidden md:block" />
                      Automotive Retail<span
                        class="absolute text-realBlack -right-15"
                        >.</span
                      >
                    </h3>
                  </div>
                  <!-- <div
                    class="w-full h-1/2 absolute bottom-0 left-0 right-0 border border-[#0E0E0E] border-opacity-50 border-b-0 rounded-t-lg"
                  ></div> -->
                  <div class="relative w-full">
                    <div class="w-full md:pb-0">
                      <div class="w-full overflow-hidden">
                        <!-- @mouseenter="pauseSlide2"
                      @mouseleave="nextSlide2" -->
                        <div
                          class="w-[300%] flex md:items-center items-start slidr"
                          :style="`margin-left: -${slide2 * 100}%`"
                        >
                          <div class="w-full">
                            <div
                              class="flex flex-col-reverse items-center justify-between w-full bg-white md:flex-row md:justify-start gap-7"
                            >
                              <div class="md:w-1/2">
                                <div
                                  class="flex-wrap items-center hidden w-full gap-6 pt-3 mb-6 md:flex font-inter"
                                >
                                  <h6
                                    class="text-3xl font-bold text-realBlack font-belgiano"
                                  >
                                    Caspian
                                  </h6>
                                  <span class="dot"></span>
                                  <p class="text-sm text-realBlack md:text-2xl">
                                    SUV
                                  </p>
                                  <span class="dot"></span>
                                  <p class="text-sm text-realBlack md:text-2xl">
                                    2024
                                  </p>
                                </div>
                                <div
                                  class="flex flex-wrap items-start justify-start w-full gap-4 py-4 md:gap-8"
                                >
                                  <p>
                                    <span
                                      class="text-sm text-newGrey opacity-65"
                                      >Top Speed</span
                                    >
                                    <br />
                                    101Km/H
                                  </p>
                                  <p>
                                    <span
                                      class="text-sm text-newGrey opacity-65"
                                      >Range</span
                                    >
                                    <br />
                                    401Km
                                  </p>
                                  <p>
                                    <span
                                      class="text-sm text-newGrey opacity-65"
                                      >Battery</span
                                    >
                                    <br />
                                    37.27kwh Lithium Ion
                                  </p>
                                  <p>
                                    <span
                                      class="text-sm text-newGrey opacity-65"
                                      >Charging</span
                                    >
                                    <br />
                                    AC/DC
                                  </p>
                                </div>
                                <div class="w-full mt-11">
                                  <h6
                                    class="text-2xl font-bold text-realBlack font-belgiano"
                                  >
                                    Exterior
                                  </h6>
                                  <div
                                    class="flex flex-wrap items-center w-full gap-3 mt-2"
                                  >
                                    <div class="vehSpec">
                                      <p>Heated Exterior Mirrors</p>
                                      <span class="bigDot"></span>
                                    </div>
                                    <div class="vehSpec">
                                      <p>Forecabin Decorative Cover</p>
                                      <span class="bigDot"></span>
                                    </div>
                                    <div class="vehSpec">
                                      <p>Interior Central Locking</p>
                                      <span class="bigDot"></span>
                                    </div>
                                    <div class="vehSpec">
                                      <p>Remote Control Smart Key</p>
                                      <span class="bigDot"></span>
                                    </div>
                                    <div class="vehSpec">
                                      <p>Keyless Entry and Start</p>
                                      <span class="bigDot md:!hidden"></span>
                                    </div>
                                  </div>
                                </div>
                                <div class="w-full mt-11">
                                  <h6
                                    class="text-2xl font-bold text-realBlack font-belgiano"
                                  >
                                    Interior
                                  </h6>
                                  <div
                                    class="flex flex-wrap items-center w-full gap-3 mt-2"
                                  >
                                    <div class="vehSpec">
                                      <p>Multi-functional Steering Wheel</p>
                                      <span class="bigDot"></span>
                                    </div>
                                    <div class="vehSpec">
                                      <p>PVC Leather Seats</p>
                                      <span class="bigDot"></span>
                                    </div>
                                    <div class="vehSpec">
                                      <p>Front-sun visor with vanity mirror</p>
                                      <span class="bigDot"></span>
                                    </div>
                                    <div class="vehSpec">
                                      <p>Wireless Phone Charging</p>
                                      <span class="bigDot"></span>
                                    </div>
                                    <div class="vehSpec">
                                      <p>Heated Front-Seat</p>
                                      <span class="bigDot md:!hidden"></span>
                                    </div>
                                  </div>
                                </div>
                                <div class="w-full mt-11">
                                  <h6
                                    class="text-2xl font-bold text-realBlack font-belgiano"
                                  >
                                    Other details
                                  </h6>
                                  <div
                                    class="flex flex-wrap items-center w-full gap-3 mt-2"
                                  >
                                    <div class="vehSpec">
                                      <p>Braking Assist</p>
                                      <span class="bigDot"></span>
                                    </div>
                                    <div class="vehSpec">
                                      <p>Body Stability Control</p>
                                      <span class="bigDot"></span>
                                    </div>
                                    <div class="vehSpec">
                                      <p>Rear Parking Radar</p>
                                      <span class="bigDot"></span>
                                    </div>
                                    <div class="vehSpec">
                                      <p>Forward Collision Warning</p>
                                      <span class="bigDot"></span>
                                    </div>
                                    <div class="vehSpec">
                                      <p>Real-Time Navigation System</p>
                                      <span class="bigDot md:!hidden"></span>
                                    </div>
                                  </div>
                                </div>

                                <div class="px-2 py-4 mt-3">
                                  <p class="text-lg">
                                    <span
                                      class="text-sm text-newGrey opacity-65"
                                      >Warranty</span
                                    >
                                    <br />
                                    36 Months
                                  </p>
                                </div>
                                <div class="w-full mt-3">
                                  <div
                                    class="inline-block py-2 pl-3 pr-4 bg-green bg-opacity-10"
                                  >
                                    <p
                                      class="font-inter leading-[26px] text-green"
                                    >
                                      Home charging available
                                    </p>
                                  </div>
                                </div>
                                <!-- <a
                                  href="/assets/doc/Qoray Seal Brochure.pdf"
                                  download
                                > -->
                                <router-link
                                  :to="{ name: 'caspian' }"
                                  class="black-btn text-center inline-block long mt-7"
                                >
                                  Explore
                                </router-link>
                                <!-- </a> -->
                              </div>
                              <div class="w-full md:w-1/2">
                                <div
                                  class="flex flex-wrap items-center w-full gap-6 pt-3 mb-6 md:hidden font-inter"
                                >
                                  <h6
                                    class="text-3xl font-bold text-realBlack font-belgiano"
                                  >
                                    Caspian
                                  </h6>
                                  <span class="dot"></span>
                                  <p class="text-sm text-realBlack md:text-2xl">
                                    SUV
                                  </p>
                                  <span class="dot"></span>
                                  <p class="text-sm text-realBlack md:text-2xl">
                                    2024
                                  </p>
                                </div>
                                <router-link
                                  :to="{ name: 'caspian' }"
                                  class="block relative w-full"
                                >
                                  <img
                                    src="@/assets/images/casp.png"
                                    alt="Beluga"
                                    class="w-full mx-auto md:mt-0 mt-[60px] hidden md:block"
                                  />
                                  <img
                                    src="@/assets/images/caspMob.png"
                                    alt="Beluga"
                                    class="w-full mx-auto md:mt-0 mt-[60px] md:hidden block"
                                  />
                                </router-link>
                                <div class="w-full mt-20"></div>
                                <div class="md:w-[80%] w-full mx-auto">
                                  <p class="text-sm text-newGrey opacity-65">
                                    From
                                  </p>
                                  <h2
                                    class="mt-1 text-3xl font-bold uppercase font-belgiano md:text-5xl"
                                  >
                                    NGN{{ prcs.caspian }},000,000.00
                                  </h2>
                                  <p class="flex items-center gap-2 pl-2 mt-2">
                                    <span class="dot"></span>10% down payment
                                  </p>
                                  <p class="flex items-center gap-2 pl-2 mt-1">
                                    <span class="dot"></span>Up to 60 monthly
                                    installments
                                  </p>
                                  <p class="flex items-center gap-2 pl-2 mt-1">
                                    <span class="dot"></span>90 days delivery
                                    time
                                  </p>
                                </div>
                                <div class="md:w-[80%] w-full mx-auto mt-10">
                                  <p class="text-sm text-newGrey opacity-65">
                                    Available colours
                                  </p>
                                  <div class="flex items-center gap-3 mt-1">
                                    <img
                                      src="@/assets/images/colors/1.png"
                                      alt=""
                                    />
                                    <img
                                      src="@/assets/images/colors/2.png"
                                      alt=""
                                    />
                                    <img
                                      src="@/assets/images/colors/3.png"
                                      alt=""
                                    />
                                    <img
                                      src="@/assets/images/colors/4.png"
                                      alt=""
                                    />
                                    <img
                                      src="@/assets/images/colors/5.png"
                                      alt=""
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="w-full">
                            <div
                              class="flex flex-col-reverse items-center justify-between w-full bg-white md:flex-row md:justify-start gap-7"
                            >
                              <div class="md:w-1/2">
                                <div
                                  class="flex-wrap items-center hidden w-full gap-6 pt-3 mb-6 font-inter md:flex"
                                >
                                  <h6
                                    class="text-3xl font-bold text-realBlack font-belgiano"
                                  >
                                    Sable
                                  </h6>
                                  <span class="dot"></span>
                                  <p class="text-sm text-realBlack md:text-2xl">
                                    SUV
                                  </p>
                                  <span class="dot"></span>
                                  <p class="text-sm text-realBlack md:text-2xl">
                                    2024
                                  </p>
                                </div>
                                <div
                                  class="flex flex-wrap items-start justify-start w-full gap-4 py-4 md:gap-8"
                                >
                                  <p>
                                    <span
                                      class="text-sm text-newGrey opacity-65"
                                      >Top Speed</span
                                    >
                                    <br />
                                    150Km/H
                                  </p>
                                  <p>
                                    <span
                                      class="text-sm text-newGrey opacity-65"
                                      >Range</span
                                    >
                                    <br />
                                    480Km
                                  </p>
                                  <p>
                                    <span
                                      class="text-sm text-newGrey opacity-65"
                                      >Battery</span
                                    >
                                    <br />
                                    64.14kWh
                                  </p>
                                  <p>
                                    <span
                                      class="text-sm text-newGrey opacity-65"
                                      >Charging</span
                                    >
                                    <br />
                                    AC/DC
                                  </p>
                                </div>
                                <div class="w-full mt-11">
                                  <h6
                                    class="text-2xl font-bold text-realBlack font-belgiano"
                                  >
                                    Exterior
                                  </h6>
                                  <div
                                    class="flex flex-wrap items-center w-full gap-3 mt-2"
                                  >
                                    <div class="vehSpec">
                                      <p>Panoramic Sunroof</p>
                                      <span class="bigDot"></span>
                                    </div>
                                    <div class="vehSpec">
                                      <p>Electric Sunshade</p>
                                      <span class="bigDot"></span>
                                    </div>
                                    <div class="vehSpec">
                                      <p>Roof Rack</p>
                                      <span class="bigDot"></span>
                                    </div>
                                    <div class="vehSpec">
                                      <p>Automatic Rain Sensing Wipers</p>
                                      <span class="bigDot"></span>
                                    </div>
                                    <div class="vehSpec">
                                      <p>Passive Entry and Start</p>
                                      <span class="bigDot md:!hidden"></span>
                                    </div>
                                  </div>
                                </div>
                                <div class="w-full mt-11">
                                  <h6
                                    class="text-2xl font-bold text-realBlack font-belgiano"
                                  >
                                    Interior
                                  </h6>
                                  <div
                                    class="flex flex-wrap items-center w-full gap-3 mt-2"
                                  >
                                    <div class="vehSpec">
                                      <p>Artificial Leather Seats</p>
                                      <span class="bigDot"></span>
                                    </div>
                                    <div class="vehSpec">
                                      <p>Manual Adjustable Steering Wheel</p>
                                      <span class="bigDot"></span>
                                    </div>
                                    <div class="vehSpec">
                                      <p>Wireless Phone Charging</p>
                                      <span class="bigDot"></span>
                                    </div>
                                    <div class="vehSpec">
                                      <p>Electric Column Shift</p>
                                      <span class="bigDot"></span>
                                    </div>
                                    <div class="vehSpec">
                                      <p>Front-seat heating</p>
                                      <span class="bigDot md:!hidden"></span>
                                    </div>
                                  </div>
                                </div>
                                <div class="w-full mt-11">
                                  <h6
                                    class="text-2xl font-bold text-realBlack font-belgiano"
                                  >
                                    Other details
                                  </h6>
                                  <div
                                    class="flex flex-wrap items-center w-full gap-3 mt-2"
                                  >
                                    <div class="vehSpec">
                                      <p>Electric Stability Control</p>
                                      <span class="bigDot"></span>
                                    </div>
                                    <div class="vehSpec">
                                      <p>Forward Collision Warning</p>
                                      <span class="bigDot"></span>
                                    </div>
                                    <div class="vehSpec">
                                      <p>Pedestrian Detection</p>
                                      <span class="bigDot"></span>
                                    </div>
                                    <div class="vehSpec">
                                      <p>Rear Parking Radar</p>
                                      <span class="bigDot md:!hidden"></span>
                                    </div>
                                  </div>
                                </div>

                                <div class="px-2 py-4 mt-3">
                                  <p class="text-lg">
                                    <span
                                      class="text-sm text-newGrey opacity-65"
                                      >Warranty</span
                                    >
                                    <br />
                                    36 Months
                                  </p>
                                </div>
                                <div class="w-full mt-3">
                                  <div
                                    class="inline-block py-2 pl-3 pr-4 bg-green bg-opacity-10"
                                  >
                                    <p
                                      class="font-inter leading-[26px] text-green"
                                    >
                                      Home charging available
                                    </p>
                                  </div>
                                </div>
                                <!-- <a
                                  href="/assets/doc/Qoray Seal Brochure.pdf"
                                  download
                                > -->
                                <router-link
                                  :to="{ name: 'sable' }"
                                  class="black-btn text-center inline-block long mt-7"
                                >
                                  Explore
                                </router-link>
                                <!-- </a> -->
                              </div>
                              <div class="w-full md:w-1/2">
                                <div
                                  class="flex flex-wrap items-center w-full gap-6 pt-3 mb-6 font-inter md:hidden"
                                >
                                  <h6
                                    class="text-3xl font-bold text-realBlack font-belgiano"
                                  >
                                    Sable
                                  </h6>
                                  <span class="dot"></span>
                                  <p class="text-sm text-realBlack md:text-2xl">
                                    SUV
                                  </p>
                                  <span class="dot"></span>
                                  <p class="text-sm text-realBlack md:text-2xl">
                                    2024
                                  </p>
                                </div>
                                <router-link
                                  :to="{ name: 'sable' }"
                                  class="block relative w-full"
                                >
                                  <img
                                    src="@/assets/images/sable.png"
                                    alt="Beluga"
                                    class="w-full mx-auto md:mt-0 mt-[60px]"
                                  />
                                </router-link>
                                <div class="w-full mt-20"></div>
                                <div class="md:w-[80%] w-full mx-auto">
                                  <p class="text-sm text-newGrey opacity-65">
                                    From
                                  </p>
                                  <h2
                                    class="mt-1 text-3xl font-bold uppercase font-belgiano md:text-5xl"
                                  >
                                    NGN{{ prcs.sable }},000,000.00
                                  </h2>
                                  <p class="flex items-center gap-2 pl-2 mt-2">
                                    <span class="dot"></span>10% down payment
                                  </p>
                                  <p class="flex items-center gap-2 pl-2 mt-1">
                                    <span class="dot"></span>Up to 60 monthly
                                    installments
                                  </p>
                                  <p class="flex items-center gap-2 pl-2 mt-1">
                                    <span class="dot"></span>90 days delivery
                                    time
                                  </p>
                                </div>
                                <div class="md:w-[80%] w-full mx-auto mt-10">
                                  <p class="text-sm text-newGrey opacity-65">
                                    Available colours
                                  </p>
                                  <div class="flex items-center gap-3 mt-1">
                                    <img
                                      src="@/assets/images/colors/1.png"
                                      alt=""
                                    />
                                    <img
                                      src="@/assets/images/colors/2.png"
                                      alt=""
                                    />
                                    <img
                                      src="@/assets/images/colors/4.png"
                                      alt=""
                                    />
                                    <img
                                      src="@/assets/images/colors/5.png"
                                      alt=""
                                    />
                                    <img
                                      src="@/assets/images/colors/6.png"
                                      alt=""
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="w-full">
                            <div
                              class="flex flex-col-reverse items-center justify-between w-full bg-white md:flex-row md:justify-start gap-7"
                            >
                              <div class="md:w-1/2">
                                <div
                                  class="flex-wrap items-center hidden w-full gap-6 pt-3 mb-6 md:flex font-inter"
                                >
                                  <h6
                                    class="text-3xl font-bold text-realBlack font-belgiano"
                                  >
                                    Javan
                                  </h6>
                                  <span class="dot"></span>
                                  <p class="text-sm text-realBlack md:text-2xl">
                                    Hatchback
                                  </p>
                                  <span class="dot"></span>
                                  <p class="text-sm text-realBlack md:text-2xl">
                                    2023
                                  </p>
                                </div>
                                <div
                                  class="flex flex-wrap items-start justify-start w-full gap-4 py-4 md:gap-8"
                                >
                                  <p>
                                    <span
                                      class="text-sm text-newGrey opacity-65"
                                      >Top Speed</span
                                    >
                                    <br />
                                    101Km/H
                                  </p>
                                  <p>
                                    <span
                                      class="text-sm text-newGrey opacity-65"
                                      >Range</span
                                    >
                                    <br />
                                    220Km
                                  </p>
                                  <p>
                                    <span
                                      class="text-sm text-newGrey opacity-65"
                                      >Battery</span
                                    >
                                    <br />
                                    Lithium 60v100ah
                                  </p>
                                  <p>
                                    <span
                                      class="text-sm text-newGrey opacity-65"
                                      >Charging</span
                                    >
                                    <br />
                                    AC/DC
                                  </p>
                                </div>
                                <div class="w-full mt-11">
                                  <h6
                                    class="text-2xl font-bold text-realBlack font-belgiano"
                                  >
                                    Exterior
                                  </h6>
                                  <div
                                    class="flex flex-wrap items-center w-full gap-3 mt-2"
                                  >
                                    <div class="vehSpec">
                                      <p>Panoramic Sunroof</p>
                                      <span class="bigDot"></span>
                                    </div>
                                    <div class="vehSpec">
                                      <p>Electric Sunshade</p>
                                      <span class="bigDot"></span>
                                    </div>
                                    <div class="vehSpec">
                                      <p>Roof Rack</p>
                                      <span class="bigDot"></span>
                                    </div>
                                    <div class="vehSpec">
                                      <p>Automatic Rain Sensing Wipers</p>
                                      <span class="bigDot"></span>
                                    </div>
                                    <div class="vehSpec">
                                      <p>Passive Entry and Start</p>
                                      <span class="bigDot md:!hidden"></span>
                                    </div>
                                  </div>
                                </div>
                                <div class="w-full mt-11">
                                  <h6
                                    class="text-2xl font-bold text-realBlack font-belgiano"
                                  >
                                    Interior
                                  </h6>
                                  <div
                                    class="flex flex-wrap items-center w-full gap-3 mt-2"
                                  >
                                    <div class="vehSpec">
                                      <p>Artificial Leather Seats</p>
                                      <span class="bigDot"></span>
                                    </div>
                                    <div class="vehSpec">
                                      <p>Manual Adjustable Steering Wheel</p>
                                      <span class="bigDot"></span>
                                    </div>
                                    <div class="vehSpec">
                                      <p>Wireless Phone Charging</p>
                                      <span class="bigDot"></span>
                                    </div>
                                    <div class="vehSpec">
                                      <p>Electric Column Shift</p>
                                      <span class="bigDot"></span>
                                    </div>
                                    <div class="vehSpec">
                                      <p>Front-seat heating</p>
                                      <span class="bigDot md:!hidden"></span>
                                    </div>
                                  </div>
                                </div>
                                <div class="w-full mt-11">
                                  <h6
                                    class="text-2xl font-bold text-realBlack font-belgiano"
                                  >
                                    Other details
                                  </h6>
                                  <div
                                    class="flex flex-wrap items-center w-full gap-3 mt-2"
                                  >
                                    <div class="vehSpec">
                                      <p>Electric Stability Control</p>
                                      <span class="bigDot"></span>
                                    </div>
                                    <div class="vehSpec">
                                      <p>Forward Collision Warning</p>
                                      <span class="bigDot"></span>
                                    </div>
                                    <div class="vehSpec">
                                      <p>Pedestrian Detection</p>
                                      <span class="bigDot"></span>
                                    </div>
                                    <div class="vehSpec">
                                      <p>Rear Parking Radar</p>
                                      <span class="bigDot md:!hidden"></span>
                                    </div>
                                  </div>
                                </div>

                                <div class="px-2 py-4 mt-3">
                                  <p class="text-lg">
                                    <span
                                      class="text-sm text-newGrey opacity-65"
                                      >Warranty</span
                                    >
                                    <br />
                                    36 Months
                                  </p>
                                </div>
                                <div class="w-full mt-3">
                                  <div
                                    class="inline-block py-2 pl-3 pr-4 bg-green bg-opacity-10"
                                  >
                                    <p
                                      class="font-inter leading-[26px] text-green"
                                    >
                                      Home charging available
                                    </p>
                                  </div>
                                </div>
                                <!-- <a
                                  href="/assets/doc/Qoray Seal Brochure.pdf"
                                  download
                                > -->
                                <router-link
                                  :to="{ name: 'javan' }"
                                  class="black-btn text-center inline-block long mt-7"
                                >
                                  Explore
                                </router-link>
                                <!-- </a> -->
                              </div>
                              <div class="w-full md:w-1/2">
                                <div
                                  class="flex flex-wrap items-center w-full gap-6 pt-3 mb-6 md:hidden font-inter"
                                >
                                  <h6
                                    class="text-3xl font-bold text-realBlack font-belgiano"
                                  >
                                    Javan
                                  </h6>
                                  <span class="dot"></span>
                                  <p class="text-sm text-realBlack md:text-2xl">
                                    Hatchback
                                  </p>
                                  <span class="dot"></span>
                                  <p class="text-sm text-realBlack md:text-2xl">
                                    2023
                                  </p>
                                </div>
                                <router-link
                                  :to="{ name: 'javan' }"
                                  class="block relative w-full"
                                >
                                  <img
                                    src="@/assets/images/javFr.png"
                                    alt="Beluga"
                                    class="w-full mx-auto md:mt-0 mt-[60px]"
                                  />
                                </router-link>
                                <div class="w-full mt-20"></div>
                                <div class="md:w-[80%] w-full mx-auto">
                                  <p class="text-sm text-newGrey opacity-65">
                                    From
                                  </p>
                                  <h2
                                    class="mt-1 text-3xl font-bold uppercase font-belgiano md:text-5xl"
                                  >
                                    NGN{{ prcs.javan }},000,000.00
                                  </h2>
                                  <p class="flex items-center gap-2 pl-2 mt-2">
                                    <span class="dot"></span>10% down payment
                                  </p>
                                  <p class="flex items-center gap-2 pl-2 mt-1">
                                    <span class="dot"></span>Up to 60 monthly
                                    installments
                                  </p>
                                  <p class="flex items-center gap-2 pl-2 mt-1">
                                    <span class="dot"></span>90 days delivery
                                    time
                                  </p>
                                </div>
                                <div class="md:w-[80%] w-full mx-auto mt-10">
                                  <p class="text-sm text-newGrey opacity-65">
                                    Available colours
                                  </p>
                                  <div class="flex items-center gap-3 mt-1">
                                    <img
                                      src="@/assets/images/colors/1.png"
                                      alt=""
                                    />
                                    <img
                                      src="@/assets/images/colors/2.png"
                                      alt=""
                                    />
                                    <img
                                      src="@/assets/images/colors/3.png"
                                      alt=""
                                    />
                                    <img
                                      src="@/assets/images/colors/4.png"
                                      alt=""
                                    />
                                    <img
                                      src="@/assets/images/colors/5.png"
                                      alt=""
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          class="flex items-center justify-end w-[100%] md:ml-0 -ml-[5%] absolute md:top-1/2 top-[20%]"
                        >
                          <div
                            class="flex items-center overflow-hidden rounded-md bg-newGrey bg-opacity-65"
                          >
                            <span
                              @click="slide2 = slide2 === 0 ? 1 : slide2 - 1"
                              class="inline-flex items-center justify-center overflow-hidden rounded-md cursor-pointer w-9 h-9 bg-newGrey bg-opacity-65"
                              ><svg
                                width="9"
                                height="17"
                                viewBox="0 0 9 17"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M0.599606 9.73863C-0.00439361 9.09688 -0.00439356 8.09583 0.599607 7.45408L6.72873 0.941886C7.04416 0.606741 7.57156 0.59076 7.9067 0.90619C8.24185 1.22162 8.25783 1.74902 7.9424 2.08416L1.81327 8.59636L7.9424 15.1086C8.25783 15.4437 8.24185 15.9711 7.9067 16.2865C7.57156 16.602 7.04416 16.586 6.72873 16.2508L0.599606 9.73863Z"
                                  fill="white"
                                />
                              </svg>
                            </span>
                            <span
                              @click="slide2 = slide2 === 2 ? 0 : slide2 + 1"
                              class="inline-flex items-center justify-center overflow-hidden rounded-md cursor-pointer w-9 h-9 bg-newGrey bg-opacity-65"
                              ><svg
                                width="9"
                                height="17"
                                viewBox="0 0 9 17"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M8.40487 9.73863C9.00887 9.09688 9.00887 8.09583 8.40487 7.45408L2.27574 0.941886C1.96031 0.606741 1.43291 0.59076 1.09777 0.90619C0.762625 1.22162 0.746643 1.74902 1.06207 2.08416L7.1912 8.59636L1.06207 15.1086C0.746644 15.4437 0.762626 15.9711 1.09777 16.2865C1.43292 16.602 1.96031 16.586 2.27574 16.2508L8.40487 9.73863Z"
                                  fill="white"
                                />
                              </svg>
                            </span>
                          </div>
                        </div>
                        <div
                          class="md:w-1/2 w-full absolute md:top-0 top-[75px] md:right-0 md:left-auto left-0 flex items-end md:justify-center justify-start md:gap-6 gap-5"
                        >
                          <div
                            v-for="(sld, index) in [
                              'Caspian',
                              'Sable',
                              'Javan',
                            ]"
                            :key="index"
                            :class="{
                              'border-t-2 border-t-[#D9D9D9]': slide2 !== index,
                              'border-t-[5px] border-t-green': slide2 === index,
                            }"
                            class="pt-3 md:w-[100px] w-[80px] flex items-center justify-center cursor-pointer"
                            @click="slide2 = index"
                          >
                            <p
                              class="px-4 font-belgiano"
                              :class="[
                                slide2 === index
                                  ? 'text-realBlack'
                                  : 'text-newGrey opacity-65',
                              ]"
                            >
                              {{ sld }}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- END VEHICLES -->
      <!-- PMD -->
      <section class="">
        <div class="h-full outer">
          <div class="h-full inner">
            <div class="h-full bg">
              <div
                class="w-full h-full ccc bg-realBlack text-white md:pt-[10vh] pt-[5vh] md:pb-[20vh] pb-16 pmd"
              >
                <div class="mx-auto max-w-xl md:w-[80%] w-[90%]">
                  <div class="heads">
                    <div>
                      <h3
                        class="w-full text-3xl font-medium text-center font-belgiano md:font-semibold md:text-5xl"
                      >
                        Power Moves Differently<span
                          class="absolute text-green -right-15"
                          >.</span
                        >
                      </h3>
                    </div>
                    <div>
                      <p
                        class="mt-3 text-sm font-normal text-center md:text-2xl font-inter"
                      >
                        Electric mobility for the future
                      </p>
                    </div>
                    <div
                      class="flex-wrap items-center justify-center hidden gap-10 mt-10 md:flex md:flex-nowrap"
                    >
                      <p
                        class="text-base font-normal text-center border-opacity-0 cursor-pointer font-inter hover:border-opacity-100 hover:border-b slidr border-b-green"
                        @click="mobile ? null : (slide = 0)"
                        :class="
                          !mobile && slide === 0
                            ? 'border-b border-opacity-100'
                            : ''
                        "
                      >
                        Financing
                      </p>
                      <p
                        class="text-base font-normal text-center border-opacity-0 cursor-pointer font-inter hover:border-opacity-100 hover:border-b slidr border-b-green"
                        @click="mobile ? null : (slide = 1)"
                        :class="
                          !mobile && slide === 1
                            ? 'border-b border-opacity-100'
                            : ''
                        "
                      >
                        Mobility as a Service
                      </p>
                      <p
                        class="text-base font-normal text-center border-opacity-0 cursor-pointer font-inter hover:border-opacity-100 hover:border-b slidr border-b-green"
                        @click="mobile ? null : (slide = 2)"
                        :class="
                          !mobile && slide === 2
                            ? 'border-b border-opacity-100'
                            : ''
                        "
                      >
                        Switch
                      </p>
                      <p
                        class="text-base font-normal text-center border-opacity-0 cursor-pointer font-inter hover:border-opacity-100 hover:border-b slidr border-b-green"
                        @click="mobile ? null : (slide = 3)"
                        :class="
                          !mobile && slide === 3
                            ? 'border-b border-opacity-100'
                            : ''
                        "
                      >
                        Manufacturing, assembling, and sales
                      </p>
                    </div>
                  </div>
                  <div
                    class="w-full lg:w-[90%] mx-auto overflow-hidden md:mt-[7vh] mt-[5vh]"
                    @mouseenter="mobile ? null : pauseSlide"
                    @mouseleave="mobile ? null : nextSlide"
                  >
                    <div
                      class="md:w-[400%] w-full flex items-center flex-col md:flex-row md:gap-0 gap-10 slidr"
                      :style="mobile ? '' : `margin-left: -${slide * 100}%`"
                    >
                      <div class="w-full">
                        <p
                          class="block w-full mb-4 text-base font-normal md:mt-10 md:hidden font-inter"
                        >
                          Financing
                        </p>
                        <div
                          class="flex flex-col items-center justify-between w-full gap-4 md:gap-24 md:flex-row"
                        >
                          <div
                            class="w-full overflow-hidden rounded-lg md:w-1/2"
                          >
                            <img
                              src="@/assets/images/fin.png"
                              alt=""
                              class="w-full"
                            />
                          </div>
                          <div class="w-full md:w-1/2">
                            <h5
                              class="text-lg md:text-[2rem] md:leading-10 font-belgiano md:mb-4 mb-2 font-medium"
                            >
                              Drive change, affordably
                            </h5>
                            <p
                              class="w-full mb-6 text-base font-normal leading-6 font-inter md:w-9/12 md:mb-12"
                            >
                              Drive towards a sustainable future by exploring
                              our financing plans tailored to your needs.
                            </p>
                            <span
                              @click="contactUs"
                              class="flex items-center gap-2 text-sm leading-5 tracking-widest uppercase"
                            >
                              <span>Contact us</span>
                              <img src="@/assets/svgs/greenArr.svg" alt="" />
                            </span>
                          </div>
                        </div>
                      </div>
                      <div class="w-full">
                        <p
                          class="block w-full mt-10 mb-4 text-base font-normal md:hidden font-inter"
                        >
                          Mobility as a Service
                        </p>
                        <div
                          class="flex flex-col items-center justify-between w-full gap-4 md:gap-24 md:flex-row-reverse"
                        >
                          <div
                            class="flex justify-end w-full overflow-hidden rounded-lg md:w-1/2"
                          >
                            <img
                              src="@/assets/images/maas.png"
                              alt=""
                              class="w-full"
                            />
                          </div>
                          <div class="w-full md:w-1/2">
                            <h5
                              class="text-lg md:text-[2rem] md:leading-10 font-belgiano md:mb-4 mb-2 font-medium"
                            >
                              Ride the electric wave.
                            </h5>
                            <p
                              class="w-full mb-6 text-base font-normal leading-6 font-inter md:w-9/12 md:mb-12"
                            >
                              Say goodbye to the hassle of ownership and say
                              hello to on-demand electric transportation.
                            </p>
                            <span
                              @click="contactUs"
                              class="flex items-center gap-2 text-sm leading-5 tracking-widest uppercase"
                              :to="{ name: 'home' }"
                            >
                              <span>Contact us</span>
                              <img src="@/assets/svgs/greenArr.svg" alt="" />
                            </span>
                          </div>
                        </div>
                      </div>
                      <div class="w-full">
                        <p
                          class="block w-full mt-10 mb-4 text-base font-normal md:hidden font-inter"
                        >
                          Switch
                        </p>
                        <div
                          class="flex flex-col items-center justify-between w-full gap-4 md:gap-24 md:flex-row"
                        >
                          <div
                            class="flex w-full overflow-hidden rounded-lg md:w-1/2"
                          >
                            <img
                              src="@/assets/images/swt.png"
                              alt=""
                              class="w-full h-auto rounded-lg"
                            />
                          </div>
                          <div class="w-full md:w-1/2">
                            <h5
                              class="text-lg md:text-[2rem] md:leading-10 font-belgiano md:mb-4 mb-2 font-medium"
                            >
                              Rev up your retro
                            </h5>
                            <p
                              class="w-full mb-6 text-base font-normal leading-6 font-inter md:w-9/12 md:mb-12"
                            >
                              Our conversion service allows you to breathe new
                              life into your old tricycles, and mini-buses
                            </p>
                            <span
                              @click="contactUs"
                              class="flex items-center gap-2 text-sm leading-5 tracking-widest uppercase"
                              :to="{ name: 'home' }"
                            >
                              <span>Contact us</span>
                              <img src="@/assets/svgs/greenArr.svg" alt="" />
                            </span>
                          </div>
                        </div>
                      </div>
                      <div class="w-full">
                        <p
                          class="block w-full mt-10 mb-4 text-base font-normal md:hidden font-inter"
                        >
                          Manufacturing, assembling, and sales
                        </p>
                        <div
                          class="flex flex-col items-center justify-between w-full gap-4 md:gap-24 md:flex-row-reverse"
                        >
                          <div
                            class="w-full overflow-hidden rounded-lg md:w-1/2"
                          >
                            <img
                              src="@/assets/images/manu.png"
                              alt=""
                              class="w-full"
                            />
                          </div>
                          <div class="w-full md:w-1/2">
                            <h5
                              class="text-lg md:text-[2rem] md:leading-10 font-belgiano md:mb-4 mb-2 font-medium"
                            >
                              Building a cleaner tomorrow
                            </h5>
                            <p
                              class="w-full mb-6 text-base font-normal leading-6 font-inter md:w-9/12 md:mb-12"
                            >
                              We're breaking the barrier of electric vehicle
                              services with our 360 development and support.
                            </p>
                            <span
                              @click="contactUs"
                              class="flex items-center gap-2 text-sm leading-5 tracking-widest uppercase"
                              :to="{ name: 'home' }"
                            >
                              <span>Contact us</span>
                              <img src="@/assets/svgs/greenArr.svg" alt="" />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- END PMD -->
      <!-- NEWSLETTER -->
      <section class="flex flex-col-reverse w-full md:flex-col">
        <section class="w-full">
          <div class="outer">
            <div class="inner">
              <div class="bg">
                <div
                  class="relative flex items-center justify-center w-full h-full bg-realBlack"
                >
                  <img
                    src="@/assets/images/newsletterBg.png"
                    class="hidden w-full md:block"
                    alt=""
                  />
                  <div
                    class="w-full h-full md:absolute relative flex items-center justify-center top-0 left-0 right-0 bottom-0 md:py-0 py-[7vh]"
                  >
                    <div class="w-[90%] max-w-xl mx-auto text-white">
                      <!-- <p class="text-xs uppercase font-inter">Newsletter</p> -->
                      <h3
                        class="font-belgiano md:font-bold font-medium md:text-[64px] text-2xl text-left md:mb-8 mb-5 relative capitalize leading-[74px]"
                      >
                        Plug into the latest updates
                      </h3>
                      <div class="w-full md:w-1/2">
                        <!-- <p class="text-base md:mt-16">
                      Join the community today to learn more about how to move
                      differently.
                    </p> -->
                        <div
                          class="flex flex-col items-start justify-between w-full gap-2 mt-3 md:items-center md:flex-row md:gap-4"
                        >
                          <input
                            type="text"
                            placeholder="address@domain.com"
                            class="md:w-[74%] w-full bg-[transparent] py-4 px-5 border border-[white] border-opacity-50 rounded-lg placeholder:text-white"
                            v-model="email"
                          />
                          <p class="mt-1 !text-red text-xs">{{ errs.email }}</p>

                          <button
                            class="text-base font-normal white-btn font-inter"
                            @click.prevent="submit"
                          >
                            Subscribe
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <!-- END NEWSLETTER -->
        <!-- NEWS -->
        <section class="w-full">
          <div class="outer">
            <div class="inner">
              <div class="bg">
                <div
                  class="flex items-center justify-center w-full h-full bg-white ccc"
                >
                  <div class="w-[95%] max-w-[1650px] mx-auto relative">
                    <!-- <div
                    class="w-full h-1/2 absolute top-0 left-0 right-0 border overflow-hidden border-[#0E0E0E] border-opacity-50 border-t-0 rounded-b-lg"
                  ></div> -->
                    <div class="w-full md:pt-[12vh] md:pb-[10vh] pt-12 pb-12">
                      <h3
                        class="relative text-3xl font-medium text-center capitalize font-belgiano md:text-5xl mb-14"
                      >
                        <!-- Read up on Qoray -->
                        Catch Up On Our News
                        <!-- <span class="absolute text-red -right-15"
                        >.</span
                      > -->
                      </h3>
                      <div
                        class="relative flex flex-col items-stretch justify-between w-full gap-8 md:flex-row"
                      >
                        <div
                          class="bg-white rounded-lg border border-[#0E0E0E] overflow-hidden border-opacity-50 md:w-1/3 w-full relative"
                        >
                          <div class="max-h-[200px] w-full overflow-hidden">
                            <img
                              src="@/assets/images/swp.webp"
                              class="w-full"
                              alt=""
                            />
                          </div>
                          <div class="w-full p-5">
                            <p class="text-base text-black font-inter">
                              <!-- Mar 1, 2024 -->
                              May 31, 2024
                            </p>
                            <a
                              href="https://nairametrics.com/2024/02/29/qore-mobility-set-to-empower-kano-women-with-electric-tricycles-in-q1-2024/?amp=1"
                              target="_blank"
                            >
                              <h6
                                class="mt-5 text-2xl capitalize font-bold leading-8 font-belgiano"
                              >
                                Qoray unveils Nigeria's first fully
                                solar-powered EV Battery Swap Station
                              </h6>
                            </a>
                            <div class="flex items-between gap-11">
                              <p
                                class="text-base font-inter text-[#535353] mt-3"
                              >
                                On May 21, 2024, Qoray, a pioneer in the
                                electric mobility space, launched Nigeria's
                                first fully solar-powered...
                              </p>
                              <a
                                href="https://nairametrics.com/2024/02/29/qore-mobility-set-to-empower-kano-women-with-electric-tricycles-in-q1-2024/?amp=1"
                                target="_blank"
                                class="relative opacity-0"
                              >
                                <img
                                  src="@/assets/svgs/blackArrAndCircle.svg"
                                  class="w-8 md:w-12"
                                  alt=""
                                />
                              </a>
                              <a
                                href="https://nairametrics.com/2024/02/29/qore-mobility-set-to-empower-kano-women-with-electric-tricycles-in-q1-2024/?amp=1"
                                target="_blank"
                                class="absolute bottom-5 right-5"
                              >
                                <img
                                  src="@/assets/svgs/blackArrAndCircle.svg"
                                  class="w-8 md:w-12"
                                  alt=""
                                />
                              </a>
                            </div>
                          </div>
                        </div>
                        <div
                          class="bg-white rounded-lg border border-[#0E0E0E] overflow-hidden border-opacity-50 md:w-1/3 w-full relative"
                        >
                          <div class="max-h-[200px] w-full overflow-hidden">
                            <img
                              src="@/assets/images/kano.jpeg"
                              class="w-full"
                              alt=""
                            />
                          </div>
                          <div class="w-full p-5">
                            <p class="text-base text-black font-inter">
                              <!-- Mar 1, 2024 -->
                              May 23, 2024
                            </p>
                            <a
                              href="https://nairametrics.com/2024/02/29/qore-mobility-set-to-empower-kano-women-with-electric-tricycles-in-q1-2024/?amp=1"
                              target="_blank"
                            >
                              <h6
                                class="mt-5 text-2xl capitalize font-bold leading-8 font-belgiano"
                              >
                                Qoray Launches, Deploys Single Largest Fleet of
                                Electric Vehicles in Nigeria
                              </h6>
                            </a>
                            <div class="flex items-between gap-11">
                              <p
                                class="text-base font-inter text-[#535353] mt-3"
                              >
                                In a historic event in Kano State, Nigeria, on
                                May 21, 2024, Qoray, a leading electric
                                mobility...
                              </p>
                              <a
                                href="https://nairametrics.com/2024/02/29/qore-mobility-set-to-empower-kano-women-with-electric-tricycles-in-q1-2024/?amp=1"
                                target="_blank"
                                class="relative opacity-0"
                              >
                                <img
                                  src="@/assets/svgs/blackArrAndCircle.svg"
                                  class="w-8 md:w-12"
                                  alt=""
                                />
                              </a>
                              <a
                                href="https://nairametrics.com/2024/02/29/qore-mobility-set-to-empower-kano-women-with-electric-tricycles-in-q1-2024/?amp=1"
                                target="_blank"
                                class="absolute bottom-5 right-5"
                              >
                                <img
                                  src="@/assets/svgs/blackArrAndCircle.svg"
                                  class="w-8 md:w-12"
                                  alt=""
                                />
                              </a>
                            </div>
                          </div>
                        </div>
                        <div
                          class="bg-white rounded-lg border border-[#0E0E0E] overflow-hidden border-opacity-50 md:w-1/3 w-full relative"
                        >
                          <div class="max-h-[200px] w-full overflow-hidden">
                            <img
                              src="@/assets/images/news.png"
                              class="w-full"
                              alt=""
                            />
                          </div>
                          <div class="w-full p-5">
                            <p class="text-base text-black font-inter">
                              <!-- Mar 1, 2024 -->
                              Feb 24, 2024
                            </p>
                            <a
                              href="https://nairametrics.com/2024/02/29/qore-mobility-set-to-empower-kano-women-with-electric-tricycles-in-q1-2024/?amp=1"
                              target="_blank"
                            >
                              <h6
                                class="mt-5 text-2xl capitalize font-bold leading-8 font-belgiano"
                              >
                                Qoray Mobility, set to empower Kano women with
                                electric tricycles in Q1 2024
                              </h6>
                            </a>
                            <div class="flex items-between gap-11">
                              <p
                                class="text-base font-inter text-[#535353] mt-3"
                              >
                                Qoray Mobility, Sterling Bank's electric
                                mobility initiative focused on accelerating the
                                adoption of...
                              </p>
                              <a
                                href="https://nairametrics.com/2024/02/29/qore-mobility-set-to-empower-kano-women-with-electric-tricycles-in-q1-2024/?amp=1"
                                target="_blank"
                                class="relative opacity-0"
                              >
                                <img
                                  src="@/assets/svgs/blackArrAndCircle.svg"
                                  class="w-8 md:w-12"
                                  alt=""
                                />
                              </a>
                              <a
                                href="https://nairametrics.com/2024/02/29/qore-mobility-set-to-empower-kano-women-with-electric-tricycles-in-q1-2024/?amp=1"
                                target="_blank"
                                class="absolute bottom-5 right-5"
                              >
                                <img
                                  src="@/assets/svgs/blackArrAndCircle.svg"
                                  class="w-8 md:w-12"
                                  alt=""
                                />
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
      <!-- END NEWS -->

      <!-- FOOTER -->

      <!-- END FOOTER -->
    </div>
  </div>
</template>

<script>
import Loading from "@/components/Loading.vue";
import Footer from "@/components/Footer.vue";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Observer } from "gsap/Observer";
import rev1 from "@/assets/images/willi.png";
import rev2 from "@/assets/images/willi2.png";
// gsap.registerPlugin(ScrollTrigger);
// gsap.registerPlugin(Observer);
// @ is an alias to /src

export default {
  name: "HomeView",
  components: { Loading, Footer },
  data() {
    return {
      email: "",
      vehs: ["panther", "savannah", "sill", "caspian", "sable", "javan"],
      approxPrice: this.$store.getters.approxPrice("panther"),
      errs: {
        email: "",
      },
      ddd: true,
      firstCarBottom:
        window.innerHeight < 650
          ? (window.innerHeight - 650) /
            (window.innerWidth > 1130
              ? window.innerWidth > 1279
                ? window.innerHeight > 632
                  ? 0.2
                  : 0.4
                : 0.05
              : 2)
          : 0,
      loading: true,
      l1: false,
      lvid: false,
      l2: false,
      slide: 0,
      timeout: "",
      slide2: 0,
      timeout2: "",
      review: {
        data: [
          {
            img: rev1,
            name: "Hajiya Habiba Abubakar Imam",
            desc: "Yar Bawai Women Cooperative Chairlady,",
            comment:
              "With the level of acceptance by leaders in the state, the number of female Electric Vehicle riders in Kano will quadruple in the nearest future.",
          },
          {
            img: rev2,
            name: "Hajiya Hauwa Ahmad Tarauni",
            desc: "Mata Zalla Women Cooperative Chairlady,",
            comment:
              "This is a huge economic blessing for women and their families in Kano state.",
          },
          // {
          //   img: "will.png",
          //   name: "Olamilekan Oluwaferi",
          //   desc: "Team Lead",
          //   comment:
          //     "We've designed advanced safety features into every element of Qoray. Including an innovative airbag that inflates between riders to keep everyone separate and protected.",
          // },
          // {
          //   img: "will2.png",
          //   name: "Sandra Chijioke",
          //   desc: "Team Lead",
          //   comment:
          //     "The product is good. Even the way they helped me with the payment plan was very friendly - like they want me to be happy x grow my transport business.",
          // },
          // {
          //   img: "will3.png",
          //   name: "Orji Oduwole",
          //   desc: "Team Lead",
          //   comment:
          //     "I am able to give my Oga more money on a weekly basis, thanks to Qoray. I support the product die!",
          // },
        ],
        current: 0,
      },
    };
  },
  methods: {
    success() {
      this.$store.dispatch("modalSuccess", {
        title: "Subscribed Successfully",
        top: `You have successfully subscribed to our newsletter.`,
        bottom: "",
      });
    },
    validateForm() {
      this.errs = { email: "" };
      var rege = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
      if (!this.email.length || !this.email.match(rege)) {
        this.errs.email = "Invalid Email";
        return false;
      } else {
        return true;
      }
    },
    async submit() {
      if (this.validateForm() === true) {
        try {
          fetch(process.env.VUE_APP_DB_URL + "/subscriptions/data.json", {
            method: "POST",
            body: JSON.stringify(this.email),
          })
            .then((res) => res.json())
            .then(async (response) => {
              // console.log(response);
              var body = {
                form: "newsletter",
                payload: {
                  email: this.email,
                },
              };
              await this.$store.dispatch("sendMail", body);
              this.success();
            })
            .catch((err) => {
              alert("something went wrong, please try again");
            });
        } catch (error) {
          alert("something went wrong, please try again");
          console.log(error);
        }
      }
    },
    contactUs() {
      this.$store.dispatch("contactUs");
    },
    orderSill() {
      this.$store.dispatch("orderNow", "Sill");
    },
    orderJavan() {
      this.$store.dispatch("orderNow", "Javan");
    },
    orderSable() {
      this.$store.dispatch("orderNow", "Sable");
    },
    orderCaspian() {
      this.$store.dispatch("orderNow", "Caspian");
    },
    orderSavannah() {
      this.$store.dispatch("orderNow", "Savannah");
    },
    animate(index) {
      // if (index === -1) {
      gsap.set(".img1", {
        opacity: 0,
      });
      gsap.to(".load1", {
        yPercent: 100,
        duration: 0.3,
        ease: "easeIn",
      });
      gsap.to(".load2", {
        xPercent: 100,
        duration: 0.3,
        ease: "easeIn",
        onComplete: () => {
          this.loading = false;
        },
      });
      // }
      // if (index === 0) {
      // }
    },
    nextSlide() {
      this.timeout = setTimeout(() => {
        this.slide = this.slide === 3 ? 0 : this.slide + 1;
      }, 5000);
    },
    pauseSlide() {
      clearTimeout(this.timeout);
    },
    nextSlide2() {
      // this.timeout2 = setTimeout(() => {
      //   this.slide2 = this.slide2 === 2 ? 0 : this.slide2 + 1;
      // }, 5000);
    },
    pauseSlide2() {
      clearTimeout(this.timeout2);
    },
    nextReview() {
      gsap.to(".rvw", {
        // x: "-100%",
        scale: 1.5,
        opacity: 0,
        duration: 0.2,
        onComplete: () => {
          gsap.set(".rvw", {
            scale: 0.5,
            // x: "100%",
            onComplete: () => {
              var length = this.review.data.length;
              if (this.review.current === length - 1) {
                this.review.current = 0;
              } else {
                this.review.current = this.review.current + 1;
              }
              gsap.to(".rvw", {
                // x: "0%",
                scale: 1,
                opacity: 1,
                duration: 0.5,
              });
            },
          });
        },
      });
    },
    prevReview() {
      gsap.to(".rvw", {
        scale: 0.5,
        opacity: 0,
        // x: "100%",
        duration: 0.2,
        onComplete: () => {
          gsap.set(".rvw", {
            scale: 1.5,
            // x: "-100%",
            onComplete: () => {
              var length = this.review.data.length;
              if (this.review.current === 0) {
                this.review.current = length - 1;
              } else {
                this.review.current = this.review.current - 1;
              }
              gsap.to(".rvw", {
                scale: 1,
                opacity: 1,
                // x: "0%",
                duration: 0.5,
              });
            },
          });
        },
      });
    },
    sett() {
      // gsap.set(".firstBg", {
      //   scale: 1.6,
      // });
      // gsap.set(".firstCar", {
      //   scale: 0.8,
      //   y: () => {
      //     if (window.innerWidth > 1809) {
      //       return "-2vh";
      //     } else if (window.innerWidth > 1687) {
      //       return "-8vh";
      //     } else if (window.innerWidth > 1668) {
      //       return "-12vh";
      //     } else {
      //       return "-12vh";
      //     }
      //   },
      //   x: () => {
      //     if (window.innerWidth > 1809) {
      //       // console.log("newww");
      //       return "-34vw";
      //     } else if (window.innerWidth > 1687) {
      //       // console.log("new");
      //       return "-36vw";
      //     } else if (window.innerWidth > 1668) {
      //       // console.log(1);
      //       return "-24vw";
      //     } else {
      //       // console.log(2);
      //       return "-32vw";
      //     }
      //   },
      // });
      // gsap.set(".firstCta", {
      //   opacity: 0,
      // });
      // gsap.set(".firstH1", {
      //   scale: 0.2,
      //   y: "37vh",
      //   x: "2%",
      // });
      // gsap.set(".lilImg", {
      //   duration: 3,
      //   ease: "power4.out",
      //   xPercent: -45,
      //   opacity: 0.8,
      // });
      // gsap.set(".firstP", {
      //   opacity: 0,
      // });
      // gsap.set(".savannahCar", {
      //   yPercent: -60,
      // });
      // gsap.set(".savannahH1", {
      //   y: "60vh",
      //   rotateX: 260,
      //   scale: 0.5,
      // });
      // gsap.set(".savannahP, .savannahCta, .sealCta, .sealP", {
      //   opacity: 0,
      // });
      // gsap.set(".sealBg", {
      //   scale: 1.5,
      // });
      // gsap.set(".sealOvl", {
      //   backgroundColor: "transparent",
      // });
      // gsap.set(".sealHnP", {
      //   y: "30vh",
      //   x: "5vw",
      // });
      // gsap.set(".selp", {
      //   opacity: 0,
      // });
      // gsap.set(".sealH1", {
      //   scale: 0.5,
      // });
      // gsap.set(".seal", {
      //   scale: 0.35,
      //   y: this.mobile ? "0" : "-40vh",
      //   x: this.mobile ? "0" : "-40vw",
      // });
    },
    // openPage() {
    //   if (this.loaded2) {
    //     document.getElementById("vd").classList.remove("fixed", "z-50");
    //     this.ddd = false;
    //     // this.observ();
    //     this.animate(0);
    //   } else {
    //     this.openPage();
    //   }
    // },
    dispp() {
      gsap.set(".img1", {
        opacity: 0,
      });
      gsap.to(".load1", {
        yPercent: 100,
        duration: 0.3,
        ease: "easeIn",
      });
      gsap.to(".load2", {
        xPercent: 100,
        duration: 0.3,
        ease: "easeIn",
        onComplete: () => {
          this.loading = false;
        },
      });
    },
  },
  computed: {
    loaded() {
      return this.lvid;
    },
    loaded2() {
      return this.l1 && this.l2;
    },
    currentReview() {
      return this.review.data[this.review.current];
    },
    mobile() {
      return window.innerWidth <= 768;
    },
    prcs() {
      return this.$store.getters.dispPrices;
    },
  },
  watch: {
    // prcs: {
    //   handler() {
    //     // console.log(this.prcs);
    //     // console.log(this.$store.getters.approxPrice("panther"));

    //     this.approxPrice = this.$store.getters.approxPrice("panther");
    //   },
    //   deep: true, // Watch deeply for changes in the state object
    // },
    slide() {
      this.pauseSlide();
      this.nextSlide();
    },
    slide2() {
      this.pauseSlide2();
      this.nextSlide2();
    },
    // loaded() {
    //   // this.$refs.vid.play();

    //   if (!this.mobile) {
    //     this.firstCarBottom =
    //       window.innerHeight < 650
    //         ? (window.innerHeight - 650) /
    //           (window.innerWidth > 1130
    //             ? window.innerWidth > 1279
    //               ? window.innerHeight > 632
    //                 ? 0.2
    //                 : 0.4
    //               : 0.05
    //             : 2)
    //         : 0;
    //     this.sett();
    //     this.animate(-1);
    //     // this.$refs.vid.play();
    //   } else {
    //     this.ddd = false;
    //     this.animate(-1);
    //   }
    // },
  },
  mounted() {
    // setInterval(() => {
    //   console.log(this.prcs);
    // }, 1000);

    this.$refs.vid.addEventListener("load", () => {
      this.dispp();
    });
    // gsap.to(".fsec", {
    //   height: 0,
    //   scrollTrigger: {
    //     scrub: true,
    //     trigger: ".fsec",
    //     start: "top top",
    //     end: "bottom top",
    //     // markers: true,
    //     pin: ".pin2",
    //   },
    // });
    // gsap.to(".pin", {
    //   height: 0,
    //   scrollTrigger: {
    //     scrub: true,
    //     trigger: ".fsec",
    //     start: "bottom top",
    //     end: "bottom -100%",
    //     // markers: true,
    //     pin: ".pin2",
    //   },
    // });
    // setTimeout(() => {
    //   this.$store.dispatch("orderNow", "Javan");
    // }, 3000);
    // console.log(rev1);
    // console.log(rev2);
    // alert(`Width: ${window.innerWidth} \n Height: ${window.innerHeight}`);
    // this.openPage();
    // setTimeout(() => {
    //   // window.scrollTo(0, 0);
    // }, 50);
    if (!this.mobile) {
      this.lvid = true;
      // this.$refs.vid.addEventListener(
      //   "canplaythrough",
      //   () => {
      //     this.lvid = true;
      //   },
      //   false
      // );
    } else {
      this.lvid = true;
    }
    // this.$refs.loadd.addEventListener(
    //   "load",
    //   () => {
    //     this.l1 = true;
    //   },
    //   false
    // );
    // this.$refs.loadd1.addEventListener(
    //   "load",
    //   () => {
    //     this.l2 = true;
    //   },
    //   false
    // );
  },
};
</script>
<style lang="scss" scoped>
* {
  box-sizing: border-box;
  user-select: none;
}
.dot {
  @apply inline-block md:w-[6px] md:h-[6px] h-[4px] w-[4px] rounded-full bg-newGrey bg-opacity-65;
}
.bigDot {
  @apply inline-block w-2 h-2 rounded-full bg-green bg-opacity-65;
}
.vehSpec {
  @apply flex items-center gap-3 md:justify-start justify-end md:w-auto w-full md:flex-row flex-row-reverse;
}
.homeb {
  background-image: url("@/assets/images/homeBan.png");
  background-size: cover;
  background-position: center center;
}

body {
  margin: 0;
  padding: 0;
  height: 100vh;
  color: white;
  background: black;
  font-family: "Cormorant Garamond", serif;
  text-transform: uppercase;
}

header {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 5%;
  width: 100%;
  z-index: 3;
  height: 7em;
  font-family: "Bebas Neue", sans-serif;
  font-size: clamp(0.66rem, 2vw, 1rem);
  letter-spacing: 0.5em;
}

section.sec {
  //display: flex;
  // align-items: center;
  // justify-content: center;
  // width: 100%;
  // top: 0;
  // position: fixed;
  // @apply md:fixed relative md:h-screen h-auto;
  // visibility: hidden;

  .outer,
  .inner {
    width: 100%;
    height: 100%;
    // overflow-y: hidden;
    // @apply md:overflow-y-hidden;
  }

  .bg {
    display: flex;
    align-items: center;
    justify-content: center;
    // position: absolute;
    // @apply md:absolute relative;
    // height: 100%;
    width: 100%;
    top: 0;
    background-size: cover;
    background-position: center;

    .ccc {
      z-index: 2;
    }

    .clip-text {
      overflow: hidden;
    }
  }
}

.bgZoom {
  transform-origin: 0;
}
.zoom {
  animation: zoom 2.5s linear infinite;
}
@keyframes zoom {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.7);
  }
  100% {
    transform: scale(1);
  }
  /* 100% {
    transform: scale(0.6);
  } */
}
.slidr {
  transition: all 0.5s ease-out;
}
.javanBg {
  background-image: url("@/assets/images/javBg.png");
  background-position: center;
  background-size: cover;
}
.bacc3 {
  background-image: url("@/assets/images/sillBg.jpg");
  background-position: center bottom;
  background-size: cover;
}
.pantBg {
  background-image: url("@/assets/images/pantherBg.png");
  background-size: cover;
  background-position: center center;
}
.caspBg {
  background-image: url("@/assets/images/caspian.png");
  background-size: cover;
  background-position: center center;
}

.savBg {
  background-image: url("@/assets/images/savannahBg.jpg");
  background-size: cover;
  background-position: center center;
}
@media (max-width: 768px) {
  .pantBg {
    background-image: url("@/assets/images/pantherBg.png");
    background-size: cover;
    background-position: 61% center;
  }
  .savBg {
    background-image: url("@/assets/images/savannahBg.jpg");
    background-size: cover;
    background-position: 65% center;
  }
  .caspBg {
    background-image: url("@/assets/images/caspian.png");
    background-size: cover;
    background-position: 60% center;
  }
  .bacc3 {
    background-image: url("@/assets/images/sillBg.jpg");
    background-position: 58% bottom;
    background-size: cover;
  }

  .bacc {
    background-image: url("@/assets/images/heroBgMob.png");
    background-position: right;
    background-size: cover;
  }
  .bacc2 {
    background-image: url("@/assets/images/savannah.jpg");
    background-position: center center;
    background-size: cover;
  }
  .bacc4 {
    background-image: url("@/assets/images/newsletterBg.png");
    background-position: right;
    background-size: cover;
  }
}
</style>
